<template>
  <v-container>
    <v-row class="mb-4">
      <h2>Feedback to Health and Coping Post - Part 1</h2>
    </v-row>
    <v-form v-for="question in questions" :key="question.inputName">
      <div class="mb-4">Example Student Post:</div>
      <p class="mb-8 mt-8">"{{ examplePosts[0].text }}"</p>
      <v-row class="mb-4 mt-4">
        {{ question.text }}
      </v-row>
      <div>
        <s-textarea v-model="inputs[question.inputName]" class="mb-4" outlined />
      </div>
    </v-form>
  </v-container>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'UPEIPsych1020A8Task3Part1',
  components: {STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        feedbackAnswer: null,
      },
      questions: [
        {
          text: 'Please provide appropriate feedback to this example post made by a student about Health and Coping.',
          inputName: 'feedbackAnswer',
        },
      ],
      examplePosts: [
        {
          postNumber: 0,
          text: 'The first ted talk spoke about how we regulate our stress. This hit home for me because I spent a lot of years poorly managing my stress keeping everything buried down until it all came out at once. It took a lot of work before I finally started dealing with one thing at a time. It’s easy to say to just deal with it but it’s not easy to do when you’ve built a habit for so long. When Esther said how we can use routines, rituals and boundaries I didn’t understand what she was talking about. When she continued to talk about it I realized how much of a difference it can make when you use those things to your advantage. In the second ted talk, when Guy talked about how when we’re at work we don’t experience stress but when we’re not at work we experience stress about work I started to realize why I may feel burnt out sometimes. When I’m doing my school work or at work I don’t feel stressed or burnt out but when I’m sitting at home thinking about all of the things I have to do that’s when the stress hits. He talked about the journal he made to show himself how much time he spent stressing about work. I found this crazy, especially because I’m probably doing the same thing. I need to start taking into consideration how much time I spend doing this and instead just getting the work done. In the third video Valerie talks about how stressful it can be when you feel like you don’t belong. She surprised me when she talked about how anxiety can also be used in a positive way if you use it to your own advantage. I always thought about anxiety as a bad thing which it can be but there’s also ways to use it to your own advantage. All three ted talks spoke about the feeling of stress. Anxiety causes stress, work causes stress and stress is something we need to regulate. I feel like all of these videos tied together to help people learn how to deal with stress which is very helpful as a university student.',
        },
      ],
    };
  },
};
</script>
